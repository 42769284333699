.auth-login {
  @import url("https://fonts.googleapis.com/css2?family=Baloo+Bhai+2:wght@400..800&family=Inter:wght@100;300;500;700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
  background: url("../../../Assets/images/login.png") no-repeat;
  /* height: 100vh; */
  background-size: cover;
  padding: 70px 0px;
  .login-main {
    background-color: #fff;
    border: 1px solid #e2ceaa;
    padding: 37px 85px;
    border-radius: 30px;
    width: 556px;
  }
  .btn-custom {
    background-color: #b32d09;
    border: 1px solid #b32d09;
    border-radius: 13px;
    padding: 11px;
    font-weight: bold;
    border-radius: 82px;
    &:hover {
      background-color: #b32d09;
      border: 1px solid #b32d09;
    }
  }
  .card-text {
    font-size: 23px;
    font-weight: 700;
    font-family: "BentonModDisp";
  }
  .back-login {
    font-family: "Inter";
    font-size: 23px;
    font-weight: 700;
    text-align: center;
  }
  .updated {
    color: "#1A1A1A";
    font-size: "16px";
    font-weight: 400;
    text-align: center;
    font-family: "Poppins";
    opacity: 0.6;
    line-height: 27px; /* 168.75% */
    margin-bottom: 8px;
  }
  .hr-line {
    border-top: 1px solid #c3c3c3;
    width: 170px;
    margin-top: 14px;
  }

  label {
    font-family: "Poppins";
    font-weight: 500;
    font-size: 13px;
    span {
      color: red;
    }
  }
  .btn-outline-custom {
    border: 1px solid #ccc !important;
    padding: 11px;
    color: #252525;
    border-radius: 13px;
    &:hover {
      background-color: #fff;
      color: #252525;
      border: 1px solid #ccc;
    }
  }
}

.create-account {
  text-align: center;
}
.create-account p {
  font-size: 12px;
  font-weight: 500;
  color: #848484;
}
.create-account span {
  text-decoration: underline;
  font-size: 12px;
  font-weight: 600;
}
