.payments-main {
  .credits-label {
    h3 {
      color: #b32d09;
      padding-left: 20px;
      // font-weight: 800;
      font-size: 18px;
      font-family: "Poppins";
    }
  }
  .payments {
    .card-details {
      h5 {
        font-family: "Poppins" !important;
        font-size: 13px;
      }
      .cards {
        position: absolute;
        top: 15px;
        right: 15px;
      }
      .added-card {
        .card-details {
          border: 1px solid #dddddd;
          background-color: #f5f5f5;
          width: 184px;
          border-radius: 9px;
          padding: 13px 12px;

          p {
            color: #84919a;
          }
        }
      }
    }
  }
  .stripe {
    display: flex;
    justify-content: end;
    p {
      color: #909090;
      font-family: Poppins;
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 184.615% */
      letter-spacing: -0.078px;
      display: flex;
      margin-top: 11px;
      margin-bottom: 18px;
    }
  }
  .payment-history {
    .history-table {
      table {
        margin-top: 30px;
        thead {
          color: #252525;
          font-family: Poppins;
          font-size: 13px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px; /* 184.615% */
          letter-spacing: -0.078px;
          tr {
            border-top: 1px solid #e3e3e3;
            border-bottom: 1px solid #e3e3e3;
          }
        }
        tbody {
          color: #323232;
          font-family: Poppins;
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px; /* 184.615% */
          letter-spacing: -0.078px;

          tr {
            th,
            td {
              margin: 20px 0px;
              background-color: #f7f7f8;
              padding: 20px 0px;
            }
            .card-col {
              color: #323232;
              font-family: Poppins;
              font-size: 13px;
              font-style: normal;
              font-weight: 700;
              line-height: 24px; /* 184.615% */
              letter-spacing: -0.078px;
              padding-left: 30px;
            }
          }
        }
      }
    }
  }
}
.btn-ctm-1 {
  background-color: #d5d5d5;
  border: 0;
  border-radius: 100px;
  padding: 6px 14px;
  color: #b32d09;
  font-weight: 600;
  font-size: 15px;
  transition: 0.3s all ease;
  &:hover {
    background-color: #b32d09;
    color: white;
  }
}
