.edit-profile {
  label {
    font-family: "Poppins";
    font-weight: 500;
    font-size: 13px;
  }
  #updatePersonalInformation {
    .personal-info {
      .edit-picture {
        display: flex;
        // justify-content: space-between;
        width: 50%;
        .picture {
          margin-right: 20px;
          img {
            width: 134px;
            height: 134px;
            border-radius: 10px;
            object-fit: contain;
            border: 1px solid rgb(240, 238, 238);
          }
        }
        .change-picture {
          .edit-buttons {
            #changePicture {
              background-color: var(--tg-red);
              border: none;
              color: #fff;
              color: #fff;
              text-align: center;
              font-family: Roboto;
              font-size: 16px;
              font-style: normal;
              font-weight: 700;
              line-height: normal;
              letter-spacing: 0.144px;
              padding: 17px;
              border-radius: 9px;
            }
            #removePicture {
              background: rgba(199, 55, 64, 0.2);
              margin-left: 11px;
              border-radius: 9px;
              color: var(--tg-red);
              text-align: center;
              font-family: Roboto;
              font-size: 16px;
              font-style: normal;
              font-weight: 700;
              line-height: normal;
              letter-spacing: 0.144px;
              border: none;
              padding: 17px;
              margin-top: 0px !important;
            }
          }
        }
      }
    }
  }
}
