@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Inter:wght@100;200;300;400;500;600;700&family=Open+Sans:wght@300;400&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Baloo+Bhai+2:wght@400..800&family=Inter:wght@100;300;500;700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
  font-family: "Poppins !important";
}

.form-control {
  /* padding: 15px !important; */
  border-radius: 82px;
  padding: 15px 16px;
  font-family: 'Poppins';
  border-radius: 82px;
   
}
.form-control:focus {
background-color: #F5F5F5;

}
a {
  text-decoration: none !important;
}
