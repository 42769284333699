.recipe-main {
  .saved-recipe {
    h5 {
      color: #323232;
      font-family: BentonModDisp;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px; /* 120% */
      letter-spacing: -0.12px;
    }
    p {
      color: #8f8f8f;
      font-family: Poppins;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px; /* 138.462% */
      letter-spacing: -0.078px;
    }
    .recipe-list {
      margin-top: 20px;
      .recipe-details {
        width: 180px;
        text-align: center;
        background-color: #f7f7f7;
        margin-right: 15px;
        border-radius: 15px;
        border: 1px solid #e5e5e5;
        background: #f7f7f7;
        padding: 15px 10px;

        .fork-class {
          width: 50px;
          height: 50px;
          padding: 9px;
          border-radius: 50%;
          text-align: center;
          background-color: #b32d09;
          margin: auto;
          svg {
            background-color: #b32d09;
          }
        }
        h5 {
          color: #000;
          font-family: Poppins;
          font-size: 13px;
          font-style: normal;
          font-weight: 700;
          line-height: 24px; /* 184.615% */
          letter-spacing: -0.078px;
          margin-top: 10px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 100px;
        }
        p {
          color: #1a1a1a;
          text-align: center;
          font-family: Poppins;
          font-size: 11px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px; /* 123.077% */
          letter-spacing: -0.078px;
          margin-bottom: 12px;
        }
        .read {
          display: flex;
          width: 142px;
          padding: 3px 10px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          border-radius: 52.222px;
          border: 1px solid #b32d09;
          background: #b32d09;
          color: #fff;
          font-family: Poppins;
          font-size: 13px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px; /* 184.615% */
          letter-spacing: -0.078px;
        }
        .remove-btn {
          margin-top: 6px;
          border-radius: 52.222px;
          border: 1px solid #b32d09;
          background: #efefef;
          display: flex;
          width: 142px;
          padding: 3px 10px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          flex-shrink: 0;
          color: #000;
          font-family: Poppins;
          font-size: 13px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px; /* 184.615% */
          letter-spacing: -0.078px;
        }
      }
      .expand {
        background-image: url(../../../Assets//images//expand-gb.png);
        background-repeat: no-repeat;
        padding: 75px 25px;
        border: none;
        margin-right: 0px !important;
        background-color: transparent;
        p {
          color: #fff;
          text-align: center;
          font-family: Poppins;
          font-size: 17px;
          font-style: normal;
          font-weight: 700;
          line-height: 19px; /* 111.765% */
          letter-spacing: -0.102px;
        }
      }
    }
  }
  .more-recipies {
    margin-top: 20px;
    .saved-recipe {
      border-radius: 20px;
      border: 1px solid rgba(226, 206, 170, 0.7);
      background: #fff;
      padding: 27px 15px;
    }
    .your-credit-points {
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: #fff;
      padding: 30px;
      border-radius: 20px;
      border: 1px solid rgba(226, 206, 170, 0.7);
      background: #fff;
      h5 {
        color: #323232;
        font-family: BentonModDisp;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px; /* 120% */
        letter-spacing: -0.12px;
      }
      p {
        color: #8f8f8f;
        text-align: center;
        font-family: Poppins;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 138.462% */
        letter-spacing: -0.078px;
      }
      .points-percentage {
        padding-top: 36px;
      }
      .saved-recipes-count {
        .total-credit {
          p {
            color: #323232;
            font-family: "Poppins";
            font-size: 13px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px; /* 120% */
            letter-spacing: -0.12px;
          }
        }
      }
    }
  }
}
.all-recipes-list {
  .recipe-main {
    .saved-recipe {
      .recipe-details {
        width: 180px;
      }
    }
  }
}

.recipe-detail {
  border: 1px solid #e2ceaa;
  padding: 25px;
  background-color: #fff;
  border-radius: 20px;
  h1 {
    color: #323232;
    font-family: BentonModDisp;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: -0.132px;
    padding: 20px;
  }
  p {
    color: #8f8f8f;
    font-family: "Poppins";
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: -0.078px;
    padding-top: 14px;
  }
}
