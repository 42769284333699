.recipe-main {
  .all-users {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #efefef;
    padding-bottom: 10px;
    .btn-custom {
      border: "1px solid ##b32d09" !important;
    }
  }
}

.recipe-detail {
  border: 1px solid #e2ceaa;
  padding: 25px;
  background-color: #fff;
  border-radius: 20px;
  h1 {
    color: #323232;
    font-family: BentonModDisp;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: -0.132px;
    padding: 20px;
  }
  p {
    color: #8f8f8f;
    font-family: "Poppins";
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: -0.078px;
    padding-top: 14px;
  }
}
