.header {
  border-bottom: 1px solid #f9f9f9;
  padding: 25px;
}
.searchbar {
  display: flex;
}
h4{
  color: #323232 !important;
font-family: BentonModDisp !important;
font-size: 20px !important;
font-style: normal !important;
font-weight: 700 !important;
line-height: 24px !important; /* 120% */
letter-spacing: -0.12px !important;
}
.search-input {
  width: 482px;
  border-radius: 100px !important;
  padding: 12px 35px !important;
  background-color: #F3F9F8;
  border: 0;
}
.notifi-bell {
  border-radius: 100px;
  background-color: #F3F9F8;
  border: 0;
  align-self: center;
  padding: 12px 15px;
  margin: 0 15px;
}
.profile-btn img {
  width: 50px;
  height: 50px;
  object-fit: cover;
}
