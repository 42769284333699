.nav-link {
  color: #6c757d;
  margin-bottom: 10px;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color:  #B32D09;

  background-color: #fff;
}

hr {
  border-color: #6c757d;
}
.sidebar {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  background-color: #fff !important;
  width: 322px;
  min-height: 100vh;
  position: fixed;
}

.text-dark {
  color: #343a40 !important;
}

.sidebar .nav-link {
  border-radius: 100px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  padding: 14px 20px;
  display: flex;

color: #fff;  margin-bottom: 20px;
}
.sidebar .nav-link.active {
  background-color: #fff;
  border: 1px solid #c4e3de;
  border-right: none;
  color: #252525;
}
.dropdown-logout {
  position: absolute;
  bottom: 10px;
  text-align: center;
}


.sidebar {
  width: 240px;
  position: fixed;
  height: 100%;
  left: 0; 
  transition: left 0.3s;
  background-color: #B32D09 !important;
  border-radius: 0px 55px 55px 0px;

}
.sidebar-1{
  padding-left: 12px;
}
.sidebar.active {
  left: 0; 
}

.hamburger {
  display: none; 
  font-size: 30px;
  cursor: pointer;
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 1050;
}

.sidebar-logo{
  background-color: #fff;
  border-radius: 30px;
  padding: 11px;
  margin-right: 48px;
}
.sidebar-logo img{
  width: 89px;
}
@media (max-width: 1024px) {
  .hamburger {
    display: block; 
  }
  /* .sidebar {
    left: -100%; /* Hide off-screen when not active
   }   */
  .sidebar.active {
    left: 0; /* Show sidebar when active */
  }
}
