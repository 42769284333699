::selection {
  color: #fff;
  background-color: var(--purple);
}
/* Variables */
:root {
  --d-grey: #242424;
  --white: #fff;
  --blue: #01193d;
  --grey: #252525;
  --black: #000;
  --yellow: #ffb715;
  --red: #c10000;
  --purple: #184e77;
  --l-grey: #757575;
  --tg-red: #c73740;
  --l-green: #4bc56d;
  --tg-success: #0ea363;
  --border-radius: 8px;
  --tg-grey: rgba(234, 233, 233, 0.3);
  --ath-grey: #f3f9f8;
}
.ath-grey {
  background-color: var(--ath-grey);
}
.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip-text {
  visibility: visible;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  width: 120px;
  font-size: 12px;
}
.pl-0 {
  padding-left: 0;
}
.tooltip-text::after {
  content: "";
  position: absolute;
  top: 100%; /* At the bottom of the tooltip */
  left: 50%;
  font-size: 12px;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent; /* Arrow effect */
}
.base-heading {
  color: #252525;
  font-family: "Poppins";
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 184.615% */
  letter-spacing: -0.078px;
}

.vertical-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.tg-grey {
  background-color: var(--tg-grey);
}
a {
  text-decoration: none;
  color: var(--grey);
}
p {
  margin-bottom: 0px;
}
.form-group {
  position: relative;
}
.required-text {
  color: var(--red) !important;
  font-size: 18px;
  position: absolute;
  top: 20px;
  right: 30px;
}
.kitchen-input {
  background-color: #f5f5f5;
  padding: 15px 16px 15px 16px;
  border-radius: 82px;
  font-size: 13px;
  &:focus {
    background-color: #f5f5f5;
    box-shadow: none !important;
    outline: none !important;
    border: 1px solid #dddddd !important;
  }
}
h1 {
  color: #252525;
  font-family: Roboto;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 39px */
  padding-left: 10px;
  margin-bottom: 0px;
  padding-left: 0px;
}
h2 {
  color: var(--grey);
  font-family: Roboto;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  margin-bottom: 0px;
}
h3 {
  color: #323232;
  font-family: BentonModDisp;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 120% */
  letter-spacing: -0.12px;
}
.kitchen-padd {
  padding: 0px 25px;
}
.kitchen-text {
  color: #8f8f8f;
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: -0.078px;
}

.CircularProgressbar-text {
  font-family: "Poppins";
  font-weight: 600;
}
.athelete-sub-heading {
  font-weight: 700;
  margin-bottom: 1rem;
  font-size: 22px;
  font-family: "DM Sans";
}
.want-more {
  // background-image: url(./Assets/);
  background-image: url(./Assets//images//want-more-bg.png);
  background-color: #b32d09;
  padding: 28px 18px;
  margin-top: 25px;
  border-radius: 20px;
  h5 {
    color: #fff;
    font-family: BentonModDisp;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 120% */
    letter-spacing: -0.12px;
  }
  p {
    color: #fff;
    font-family: "Poppins";
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 123.077% */
    letter-spacing: -0.078px;
  }
  button {
    color: #000;
    font-family: "Poppins";
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 184.615% */
    letter-spacing: -0.078px;
    border-radius: 52.222px;
    background: #fff;
    border: 1px solid #fff;
    padding: 12px 30px;
  }
}
.credit-points {
  background-color: #fff;
  border-radius: 20px;
  border: 1px solid rgba(226, 206, 170, 0.7);
  padding: 24px;
}
.green-dot {
  background-color: #008b6e;
  width: 8px;
  height: 8px;
  padding: 6px;
  border-radius: 50%;
}
.ocean-dot {
  background-color: #30ffd4;
  width: 8px;
  height: 8px;
  padding: 6px;
  border-radius: 50%;
}

.grey-dot {
  background-color: #a2a2a2;
  width: 8px;
  height: 8px;
  padding: 6px;
  border-radius: 50%;
}

.form-check-input:checked {
  background-color: #b32d09;
  border: 1px solid #b32d09;
}
.btn-w {
  width: max-content !important;
}
.topgear-dashboard {
  // background-color: #f5f5f5;
  background-image: url("./Assets//images//login.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;
}
.overlay {
  position: absolute;
  width: 100px;
  height: 287px;
  border-radius: 0px 20px 20px 0px;
  opacity: 0.9;
  background: linear-gradient(270deg, #fff 0%, rgba(255, 255, 255, 0) 100%);
  top: 0;
  z-index: 99;
  right: -5px;
}
.tg-scroll-x {
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
  overflow-y: hidden;
  padding-bottom: 0 !important;
  scrollbar-width: 5px; /* Firefox */
  -ms-overflow-style: none; /* IE/Edge */

  &::-webkit-scrollbar {
    width: 5px; /* Hide scrollbar for Webkit-based browsers */
    height: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #f5f5f5;
    border-radius: 10px;
  }
}

.tg-scroll-y {
  // display: flex;
  overflow-y: scroll !important;
  overflow-x: hidden;
  scrollbar-width: thin; /* Firefox */
  -ms-overflow-style: none; /* IE/Edge */

  &::-webkit-scrollbar {
    width: 7px !important;
    height: 3px !important; /* Width of the scrollbar for Webkit-based browsers */
  }

  &::-webkit-scrollbar-thumb {
    background-color: #f5f5f5; /* Color of the scrollbar thumb */
    border-radius: 10px; /* Border radius of the scrollbar thumb */
  }
}

.bg-white {
  background-color: #fff !important;
}
.page-bg {
  background-color: #f5f5f5;
}
.btn-custom-started {
  width: 323px !important;
}
.btn-custom {
  font-size: 14px;
  font-weight: 700;
  font-family: "Poppins";
  padding: 10px 14px;
  line-height: 24px;
  background-color: #b32d09;
  color: #fff;
  width: 100%;
  border-radius: 30px;
  &:hover {
    font-size: 14px;
    font-weight: 700;
    font-family: "Poppins";
    padding: 10px 14px;
    line-height: 24px;
    background-color: #b32d09;
    color: #fff;
    width: 100%;
    border-radius: 30px;
  }
}
.btn-custom-white {
  font-size: 14px;
  font-weight: 700;
  font-family: "Poppins";
  padding: 10px 14px;
  line-height: 24px;
  background-color: #fff;
  color: #b32d09;
  width: 100%;
  border-radius: 30px;
  border: 1px solid #b32d09;
  &:hover {
    color: #fff;
    background-color: #fff;
    border: 1px solid #252525;
    background-color: #b32d09;
  }
}
.logout-btn {
  display: flex;
}
.read-btn {
  display: flex;
  width: 142px;
  padding: 3px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 52.222px;
  border: 1px solid #b32d09;
  background: #b32d09;
  color: #fff;
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 184.615% */
  letter-spacing: -0.078px;
}

.modal-btn-custom {
  color: #b32d09;
  // transition: 0.5s all ease;
  transition: border-width 0.5s ease;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #fff;

  &:hover {
    border-bottom: 1px solid #55bfa9;
  }
}
// .modal-btn-custom {
//   border-radius: 6px;
//   background: var(--tg-red);
//   color: var(--white, #fff);
//   text-align: center;
//   font-family: Inter;
//   font-size: 17px;
//   font-style: normal;
//   font-weight: 600;
//   line-height: 24px; /* 141.176% */
//   width: 100%;

//   padding: 14px;
//   border: 1px solid var(--tg-red);
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   svg {
//     margin-right: 5px;
//     font-size: 18px;
//   }
// }
.modal-content {
  border-radius: 20px !important;
  padding: 10px;
}
.btn-discard {
  font-size: 20px;
  font-weight: 700;
  font-family: Inter;
  padding: 24px 40px;
  line-height: 24px;
  border: 1px solid #d9d9d9;
  background: var(--tg-grey);
  color: var(--grey);
  text-align: center;
  font-style: normal;
  border-radius: var(--border-radius);
}
.modal-btn-discard {
  border-radius: 6px;
  border: 1px solid #e0e0e0;
  background: #fafafa;
  color: var(--grey);
  text-align: center;
  font-family: Inter;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 141.176% */
  width: 100%;
  padding: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    margin-right: 5px;
    font-size: 18px;
  }
}

.disable-btn {
  font-family: Inter;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0px;
  background: #dbdbdb80;
  text-align: center;
  margin-top: 30px;
  width: 100%;
  border: none;
  padding: 20px;
  pointer-events: auto !important;
  cursor: not-allowed !important;
  border-radius: var(--border-radius);
}
.btn-custom-dark {
  &:hover {
    background: var(--grey);
    color: var(--white);
  }
  color: var(--white);
  text-align: center;
  font-family: Inter;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 25.5px;
  border-radius: var(--border-radius);
  background: var(--grey);
  width: 100%;
  padding: 18px 40px;
}
.add-user-button {
  svg {
    color: var(--tg-red);
    background-color: var(--white);
    margin-right: 5px;
  }
  color: var(--c-73740, #c73740);
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
  border: 1px dashed var(--tg-red);
  background-color: rgba(199, 55, 64, 0.05);
  border-radius: var(--border-radius);
  padding: 15px 10px;
  width: 100% !important;
  display: block;
}
.or-seperator {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 48px 0px;
  .or-border {
    width: 305.53px;
    border-bottom: 1px solid #ccc;
  }
  .or-text {
    p {
      margin-bottom: 0px;
      color: #ccc;
      text-align: center;
      font-family: Roboto;
      font-size: 22px;
      font-style: normal;
      font-weight: 500;
      padding: 0px 10px;
      opacity: 0.5;
    }
  }
}
.topgear-dashboard {
  .dashboard-sidebar {
    font-family: "Inter", sans-serif;
    position: absolute;
    z-index: 9999;
    padding-bottom: 24px;
    border-radius: 20px;
    position: fixed;
    width: 240px;
    height: 97vh;
    overflow: scroll;
    overflow-x: hidden;
    &::-webkit-scrollbar {
      width: 0px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: transparent;
    }
    .sidebar-profile {
      border-radius: 14px;
      margin: auto;
      padding: 30px 10px 10px;
      background-color: #fff;
      margin: 0px 30px;
      text-align: center;
      .sidebar-logo {
        text-align: center;
        img {
          width: 89px;
        }
      }
      hr {
        opacity: 0.06;
        background: #fff;
        height: 3px;
        margin: 32px 0px;
        width: 100%;
      }
      .profile {
        .profile-img {
          img {
            width: 66px;
            height: 66px;
            flex-shrink: 0;
            border-radius: 16px;
          }
        }
        .profile-name {
          h3 {
            color: #fff;
            font-family: Roboto;
            font-size: 20px;
            font-style: normal;
            font-weight: 800;
            line-height: 30px;
            margin-bottom: 0px;
            text-overflow: ellipsis;
            overflow: hidden;
            width: 130px;
          }
          p {
            font-family: "Inter", sans-serif;
            opacity: 0.5;
            color: #fff;
            font-family: Inter;
            font-size: 15px;
            font-style: normal;
            font-weight: 600;
            line-height: 150%; /* 22.5px */
          }
        }
      }
    }
  }
}
.candidates-info {
  .info-head {
    border-radius: 10px;
    background: var(--grey);
    color: var(--white);
    // text-align: center;
    padding: 20px;
    margin-bottom: 20px;
    h6 {
      color: #fff;
      font-family: Roboto;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 16px; /* 88.889% */
      text-transform: uppercase;
      margin-bottom: 0px;
    }
  }
  .info-body {
    padding-right: 20px;
    height: 700px;
    overflow: scroll;
    overflow-x: hidden;
    &::-webkit-scrollbar {
      width: 9.785px;
      height: 229.994px;
      // flex-shrink: 0;
      border-radius: 36px;
      background: var(--grey);
    }
    &::-webkit-scrollbar-thumb {
      background-color: #f5f5f5;
      border-radius: 10px;
    }

    .user-info {
      background-color: #f5f5f5;
      margin-bottom: 10px;
      border-radius: 10px;
      .userName {
        padding: 15px;
        align-items: center;
        display: flex;
        img {
          width: 60px;
          height: 60px;
          flex-shrink: 0;
          border-radius: 6px;
          box-shadow: 0px 4px 22px 0px rgba(0, 0, 0, 0.1) inset;
        }
        p {
          color: var(--grey);
          font-family: Roboto;
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: 135.687%; /* 27.137px */
          padding-left: 16px;
        }
      }
      .role {
        color: var(--grey);
        font-family: Roboto;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 135.687%; /* 24.424px */
        padding: 30px 0px;
        ul {
          display: flex;
          margin-bottom: 0px;
          li {
            margin-right: 30px;
          }
        }
      }
      .contact-info {
        .information {
          display: flex;
          span {
            color: var(--tg-red);
          }
          h6 {
            margin-bottom: 0px;
            color: var(--grey);
            font-family: Roboto;
            font-size: 19px;
            font-style: normal;
            font-weight: 700;
            line-height: 135.687%; /* 25.781px */
            padding-left: 10px;
            padding-bottom: 10px;
          }
          p {
            padding-left: 10px;
            opacity: 0.7;
            color: var(--grey);
            font-family: Inter;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 130%; /* 20.8px */
          }
        }
        .call-info,
        .mail-info {
          background-color: transparent;
          span {
            color: #000;
          }
        }
      }
    }
  }
}

.dashboard-sidebar {
  position: fixed;
}
.sidebar-menu {
  width: 282.31px;
  margin: auto;
  margin: 25px auto auto;
  list-style-type: none;
  margin-left: 0px;
  padding-left: 0px;
  display: flex;
  flex-direction: column;
  padding: 20px;

  .list-btn {
    color: "#ffffff";
    font-family: "Poppins";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 24px */
    padding: 14px;
    border-radius: 10px;
    cursor: pointer;
    transition: 0.3s all ease;
    margin-bottom: 10px;
    // background-color: rgba(33, 33, 33, 0.605);
    display: flex;
    span {
      padding-right: 10px;
    }
    &:hover {
      border-radius: var(--border-radius);
      background-color: #fff !important;
      color: #fff !important;
    }
    &.active {
      // border-radius: var(--border-radius);
      // background-color: var(--) !important;
      // border: 1px solid #55bfa9;
      background-color: transparent;
      color: #000 !important;
      background-color: #fff;
    }
    .selected {
      // border-radius: var(--border-radius);
      // background-color: var(--tg-red) !important;
      color: #55bfa9;
      background-color: transparent;
    }
  }
}
@media (max-width: 768px) {
  .dashboard-sidebar {
    display: none;
  }
  .kitchen-padd {
    padding: 15px 25px;
  }
  .athelete-sub-heading {
    font-size: 18px;
    // font-weight: 500;
  }
  .btn-custom {
    font-size: 12px;
    padding: 8px 12px;
    white-space: nowrap;
    font-weight: 500;
  }
  .list-btn {
    color: #fff;
  }
  .want-more {
    margin-bottom: 25px !important;
  }
  .custom-btns {
    margin-top: 22px;
    text-align: center;
  }
  .btn-custom-white {
    padding: 10px !important;
  }
  .sidebar-menu {
    width: 100%;
  }
  .dashboard-sidebar {
    max-width: 50%;
  }
  .close-sidebar {
    font-size: 38px !important;
  }
  .topgear-dashboard {
    height: 100vh;
  }
}

@media (max-width: 600px) {
  .dashboard-sidebar {
    max-width: 80% !important;
  }
}
