.dashboard-main {
  font-family: "BentonModDisp";
  margin-left: 240px;
  width: 100%;
  .credit-points {
    h5 {
      color: #323232;

      font-family: BentonModDisp;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px; /* 120% */
      letter-spacing: -0.12px;
    }
    p {
      color: #8f8f8f;
      font-family: "Poppins";
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px; /* 138.462% */
      letter-spacing: -0.078px;
      padding-top: 14px;
    }
    .points {
      .total-credit {
        border-right: 1px solid #e5e5e5;
        p {
          color: #000;
          font-family: "Poppins";
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px; /* 150% */
          letter-spacing: -0.096px;
        }
      }
    }

    .points-percentage {
      display: flex;
      justify-content: end;
    }
  }
  .how-to-use {
    margin-top: 20px;
    .check-balance {
      margin-top: 22px;
      h5 {
        color: #b32d09;

        font-family: "Poppins";
        font-size: 13px;
        font-style: normal;
        font-weight: 700;
        line-height: 18px; /* 138.462% */
        letter-spacing: -0.078px;
        margin-bottom: 0px;
      }
      p {
        color: #8f8f8f;
        padding-top: 0px !important;
        font-family: "Poppins";
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        // line-height: 18px; /* 138.462% */
        letter-spacing: -0.078px;
      }
    }
  }
}

.saved-recipies {
  // padding: 13px 28px;
  background-color: #fff;
  border: 1px solid rgba(226, 206, 170, 0.7);
  border-radius: 20px;
  h1 {
    color: #323232;
    font-family: BentonModDisp;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 109.091% */
    letter-spacing: -0.132px;
    padding: 20px;
  }
  .saved-recipies-inner {
    // height: 610px;
    height: 77vh;
    .recipie-one {
      display: flex;
      padding: 20px 20px;
      border-bottom: 1px solid #e1e1e1;
      .main-recipie {
        padding-left: 6px;
        p {
          overflow: hidden;
          color: #1a1a1a;
          text-overflow: ellipsis;
          font-family: "Poppins";
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px; /* 153.846% */
          text-transform: capitalize;
        }
        a {
          color: #b32d09;
          font-family: Poppins;
          font-size: 13px;
          font-style: normal;
          font-weight: 700;
          line-height: 24px; /* 184.615% */
          letter-spacing: -0.078px;
          border-bottom: 1px solid #b32d09;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .dashboard-main {
    margin-left: 0px;
    height: 100vh;
  }
  .points-percentage {
    justify-content: center !important;
    padding-top: 20px;
  }
}
